









































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from 'vue';
import cmsImageToUrl from '~/helpers/cms/cmsImageToUrl';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';
import RenderContent from '~/cms/RenderContent.vue';

export default defineComponent({
  name: 'VerticalLanderCategory',
  components: { RenderContent },
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    image: {
      type: Object as PropType<CmsImageResponse>,
      required: true
    },
    subcategories: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    return {
      imageSource: computed(() => cmsImageToUrl(props.image))
    };
  }
});
